<template>
    <div class="page-container">
  
      <!-- The warning modal will only be visible if we try to transfer some material that are currently labeled as "in use" -->
      <confirm-modal @cancel="toggleWarningModal" @yes="transferCSV" :visible="warningModal.isVisible" :toggle="toggleWarningModal">
        <p>{{ $t('pages.export_csv.warningModal.mainMsg') }}</p>
        <p class="mt-2 italic"><i class="fas fa-exclamation-triangle mr-2 text-yellow-400"></i>{{ $t('pages.export_csv.warningModal.warningMsg') }}</p>
      </confirm-modal>
  
  
      <div class="h-full flex flex-col pb-14">
        <!-- Top bar (users / scan) -->
        <div class="flex mb-5">
            <!-- From user -->
            <div class="w-full flex">
                <label class="mr-2 mt-2">{{ $t('pages.export_csv.top.from') }}: </label>
                <!-- loader -->
                <span v-if="!users.isLoaded">
                    <i class="mt-3 fas fa-spinner fa-spin"></i>
                </span>
                <!-- Select from user -->
                <multiselect v-else v-model="selection.fromUser" @select="fromUserOnSelected" track-by="id"
                                :custom-label="userLabelDisplayed"
                                :placeholder="$t('pages.export_csv.top.selectUser')"
                                :options="users.selectOptions"
                                group-label="role"
                                group-values="users"
                                :searchable="true"
                                :allow-empty="false"
                                :showLabels="false">
                    <template slot="noResult">{{ $t('pages.export_csv.top.noResult') }}</template>
                </multiselect>
            </div>

            <div class="w-full flex"></div>

            <div class="w-full flex text-right">
                <label class="mr-2 mt-2">{{ $t('pages.export_csv.top.from') }}: </label>
                <!-- loader -->
                <span v-if="!culture.isLoaded">
                    <i class="mt-3 fas fa-spinner fa-spin"></i>
                </span>
                <!-- Select from user -->
                <multiselect v-else v-model="selection.culture" @select="fromCultureOnSelected" track-by="id"
                                :custom-label="cultureLabelDisplayed"
                                :placeholder="$t('pages.export_csv.top.selectCulture')"
                                :options="culture.selectOptions"
                                group-label="use"
                                group-values="culture"
                                :searchable="true"
                                :allow-empty="true"
                                :showLabels="false">
                    <template slot="noResult">{{ $t('pages.export_csv.top.noResult') }}</template>
                </multiselect>
            </div>
        </div>
  
        <!-- Transfer part -->
        <div class="flex h-full">
          <!-- Left col (From inventory) -->
          <div class="transfer-card pb-16">
            <div class="h-full">
              <div v-if="!inventory.isLoaded" class="h-full text-center flex flex-col justify-center ">
                <div v-if="selection.fromUser === null" class="font-light text-xl">
                  {{ $t('pages.export_csv.inventory.selectUserInfo') }}
                </div>
                <div v-else-if="selection.culture === null" class="font-light text-xl">
                  {{ $t('pages.export_csv.inventory.cultureUserInfo') }}
                </div>
                <div v-else-if="!inventory.isLoaded" class="text-4xl text-gray-600">
                  <i class="fas fa-spinner fa-spin"></i>
                </div>
              </div>
              <div v-else class="h-full">
                <div v-if="inventory.data.length === 0" class="h-full text-center flex flex-col justify-center font-light text-xl text-center">
                  {{ $t('pages.export_csv.inventory.noMaterial') }}
                </div>
                <div v-else class="h-full">
                  <!-- Tool bar (filter / search) -->
                  <div class="pt-4 pb-2.5 text-center">
                    <!-- Search -->
                    <div class="w-full">
                      <input v-model="selection.searchTerm" type="text" class="search-input" :placeholder="$t('pages.export_csv.inventory.searchPlaceholder')">
                    </div>
                  </div>
                  <!-- Inventory content -->
                  <div class="max-h-full w-full overflow-y-auto">
                    <div class="flex flex-col px-2">
                      <!-- No search results -->
                      <div v-if="filteredItems.length === 0" class="h-full text-center flex flex-col justify-center font-light text-xl text-center mt-14">
                        <span>{{ $t('pages.export_csv.inventory.searchNoResult', {term: selection.searchTerm}) }}</span>
                      </div>
                      <!-- Search result OK -->
                      <div v-else v-for="item in filteredItems" :key="item.id + item.type" @click="toggleSelectedItem(item)" class="inventory-item flex" :class="{'item--selected': item.selected}">
                        <span class="w-full">{{ item.id }} - <span class="font-light">{{ $t("backend_trans_keys."+item.typeTransKey) }}</span></span>
                        <span class="w-1/3">{{ $t('pages.export_csv.inventory.' + item.type) }}</span>
                        <span class="w-1/3 text-right">
                          <span class="item__status" :class="[item.used ? 'item__status--used' : 'item__status--free']">{{ item.used ? $t('pages.export_csv.inventory.statusUsed') : $t('pages.export_csv.inventory.statusFree') }}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <!-- Middle col (Arrow) -->
          <div class="min-h-full flex flex-col justify-center w-1/5">
            <div class="text-center">
              <i class="text-gray-600 fas fa-arrow-right text-5xl"></i>
            </div>
          </div>
  
          <!-- Right col (Materials that will be transferred) -->
          <div class="transfer-card relative pb-32">
  
            <!-- Title -->
            <div class="text-center mt-4 mb-1.5">
              <h1 class="text-xl font-normal">{{ $t('pages.export_csv.transferZone.title', {count: selectedItems.length}) }}</h1>
            </div>
  
            <!-- Materials that will be transferred -->
            <div class="max-h-full w-full overflow-y-auto">
              <div class="flex flex-col px-2">
                <div v-for="item in selectedItems" :key="item.id + item.type" @click="toggleSelectedItem(item)" class="inventory-item item--unselected flex">
                  <span class="w-full">{{ item.id }} - <span class="font-light">{{ $t("backend_trans_keys."+item.typeTransKey) }}</span></span>
                  <span class="w-1/3">{{ $t('pages.export_csv.inventory.' + item.type) }}</span>
                  <span class="w-1/3 text-right">
                    <span class="item__status" :class="[item.used ? 'item__status--used' : 'item__status--free']">{{ item.used ? $t('pages.export_csv.inventory.statusUsed') : $t('pages.export_csv.inventory.statusFree') }}</span>
                  </span>
                </div>
              </div>
            </div>
  
            <!-- Transfer button -->
            <div class="absolute bottom-6 w-full">
              <div v-if="selectedItems.length > 0">
                <div v-if="selection.toUser === null" class="text-center text-red-500 font-semibold">
                  <span>{{ $t('pages.export_csv.transferZone.errorNoDestUser') }}</span>
                </div>
                <div v-else class="mx-auto flex justify-center">
                  <button @click="onClickTransfer" class="w-1/2 btn btn--success text-xl -ml-10"><i class="fas fa-check mr-2"></i>{{ $t('pages.export_csv.transferZone.validate') }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import {apiGetRequest,generateDownloadCsvLink} from "../../../utils";
  import {
    API_HISTORY_FARMERS_CULTURES,
    API_USERS_ALL,
    //API_USERS_SENSORS_ALL,
    API_HISTORY_CULTURES_TRANSMITTERS,
    API_HISTORY_CULTURES_TRANSMITTERS_SENSORS,
    //API_SENSORS_MEASURES_SUFFIX,
    API_CULTURES_SHOW2,
    API_SENSORS_SHOW,
    API_SENSORS_MEASURES_CSVEXPORT_SUFFIX,
  } from "../../../utils/constants";
  import Multiselect from "vue-multiselect";
  import ConfirmModal from "../../../components/modals/confirm-modal";
  
  export default {
    name: "ExportCSV",
    components: {Multiselect, ConfirmModal},
    data: function() {
      return {
        users: {
          isLoaded: false,
          // Will be used to generate all groups in the html select group
          optGroups:{
            admins: this.$t('pages.export_csv.top.admins'),
            farmers: this.$t('pages.export_csv.top.farmers')
          },
          data: null,
          selectOptions: null // The data that will be used in the vue-multiselect component
        },
        culture: {
          isLoaded: true,
          optGroups:{
            use: this.$t('pages.export_csv.top.use'),
            archived: this.$t('pages.export_csv.top.archived')
          },
          data: null, // Real array that will contain all material (inventory) of the selected user
          selectOptions: [] // The data that will be used in the vue-multiselect component
        },
        transmitter: {
          id : []
        },
        inventory: {
          isLoaded: false,
          SensorPromise: [],
          data: [], // Real array that will contain all material (inventory) of the selected user
          filtered: [], // The array that will be really displayed to the screen, it will change depends on the filter, search etc... (but references same object as the inventory array)
          types: {
            transmitter: "TRANSMITTER",
            sensor: "SENSOR"
          }
        },
        selection: {
          fromUser: null,
          culture : null,
          sensor  : null,
          searchTerm: '',
          scanValue: null
        },
        scanError: {
          display: false,
          msg: null
        },
        warningModal: {
          isVisible: false
        },
        loadingCSV: false
      }
    },
    mounted() {
      this.loadUsers()
      this.culture.selectOptions = []
      this.culture.isLoaded = true
      
    },
    computed: {
      /**
       * Return all selected items
       * @returns {*[]|*}  An array containing all selected items or an empty array
       */
      selectedItems: function() {
        return this.inventory.data === null ? [] : this.inventory.data.filter(item => item.selected)
      },
      /**
       * Return a filtered array according the current search term typed
       * @returns A filtered array according the current search term typed
       */
      filteredItems: function() {
        return this.inventory.filtered.filter((item) => {
          let searchTermLower = this.selection.searchTerm.toLowerCase()
          return (
              // Searching in ID
              item.id.toString().includes(this.selection.searchTerm) ||
              // Searching in real type (dendrometer, ...)
              this.$t('backend_trans_keys.' + item.typeTransKey).toLowerCase().includes(searchTermLower) ||
              // Searching by general type (transmitter or sensor)
              this.$t('pages.export_csv.inventory.' + item.type).toLowerCase().includes(searchTermLower) ||
              // Searching by "free" or "used"
              (item.used && this.$t('pages.export_csv.inventory.statusUsed').toLowerCase().includes(searchTermLower)) ||
              (!item.used && this.$t('pages.export_csv.inventory.statusFree').toLowerCase().includes(searchTermLower)))
        })
      }
    },
    methods: {
      /**
       * Method used to display a custom label in the dropdown list for <select> fromUser and toUser
       */
      userLabelDisplayed ({ firstname, lastname, email }) {
        return `${firstname} ${lastname} [${email}]`
      },
      /**
       * Callback when we select a user in the fromUser select
       * @param selectedUser The selected user
       */
      fromUserOnSelected: function(selectedUser) {
        this.selection.fromUser = selectedUser
        this.loadCulture()
      },
      cultureLabelDisplayed ({ name }) {
        return `${name}`
      },
      /**
       * Callback when we select a user in the fromUser select
       * @param selectedUser The selected user
       */
      fromCultureOnSelected: function(selectedCulture) {
        this.selection.culture = selectedCulture
        this.loadInventoryTransmitter()
        setTimeout(this.loadInventorySensor,500)
      },
      /**
       * Callback when we select a user in the toUser select
       * @param selectedUser The selected user
       */
      toUserOnSelected: function(selectedUser) {
        this.selection.toUser = selectedUser
      },
      /**
       * Load all users available
       */
      loadUsers: function(){
        this.users.isLoaded = false
        apiGetRequest(API_USERS_ALL)
            .then((res) => {
              this.users.data = res.data

              // Generate the formatted selectOptions array to respect what is asked by the Vue-Multiselect lib.
              let selectOptions = []
              for(let userType in this.users.optGroups){
                let roleName = this.users.optGroups[userType]
                let usersArray = this.users.data[userType]
                if(roleName != this.users.optGroups.admins)
                {
                    selectOptions.push({
                    role: roleName,
                    users: usersArray
                    })
                }
              }
              this.users.selectOptions = selectOptions
              console.log(this.users.selectOptions)
              this.users.isLoaded = true
            })
      },
      /**
       * Load the inventory of the current selected user
       */
      loadCulture: function(){
        // Back to zero
        this.culture.isLoaded = false
        this.culture.data = []
        // Send all our requests and wait on them
        apiGetRequest(API_HISTORY_FARMERS_CULTURES(this.selection.fromUser.id))
        .then((res) => {
            this.culture.data = res.data
            let selectOptions = []
            let archived_type = 0;
            for(let culturetype in this.culture.optGroups){
                let archivedName = this.culture.optGroups[culturetype]
                let CultureArray = []
                for(let culture_index in this.culture.data){
                  if (this.culture.data[culture_index].archived == archived_type)
                  {
                    CultureArray.push(this.culture.data[culture_index])
                  }
                }
                selectOptions.push({
                  use: archivedName,
                  culture: CultureArray
                })
                archived_type++;
            }
            this.culture.selectOptions = selectOptions
            this.culture.isLoaded = true
        })
      },
      /**
       * Load the Sensor of the curent culture
       */
      loadOngoingInventoryTransmitter: function() {
        apiGetRequest(API_CULTURES_SHOW2(this.selection.culture.id))
          .then((res) => {
            this.selectedCulture = res.data
            console.log("this.culture",this.selectedCulture)
            let list_transmiter = this.selectedCulture.transmitters.map((t) => {
              console.log(t)
              t.type = this.inventory.types.transmitter
              t.selected = false
              return t
            })
            for(let transmitter in list_transmiter)
            {
              this.inventory.SensorPromise.push(apiGetRequest(API_HISTORY_CULTURES_TRANSMITTERS_SENSORS(this.selection.culture.id,list_transmiter[transmitter].id)))
            }
          })     
      },
      /**
       * Load the Sensor of the curent culture
       */
      loadArchivedInventoryTransmitter: function() {
        let transmittersPromise = apiGetRequest(API_HISTORY_CULTURES_TRANSMITTERS(this.selection.culture.id))
          Promise.all([transmittersPromise])
          .then((res) => {
            let list_transmiter = res[0].data.map(t => {
              t.type = this.inventory.types.transmitter
              t.selected = false
              return t
            })
            for(let transmitter in list_transmiter)
            {
              this.inventory.SensorPromise.push(apiGetRequest(API_HISTORY_CULTURES_TRANSMITTERS_SENSORS(this.selection.culture.id,list_transmiter[transmitter].id)))
            }
          })      
      },
      /**
       * Load the inventory of the current selected user
       */
      loadInventoryTransmitter: function() {
        // Back to zero
        this.inventory.SensorPromise = []
        // Send all our requests and wait on them
        console.log(this.selection.culture.archived)
        if(this.selection.culture.archived)
        {
          console.log("this.loadArchivedInventoryTransmitter();")
          this.loadArchivedInventoryTransmitter();
        }
        else
        {
          console.log("this.loadOngoingInventoryTransmitter();")
          this.loadOngoingInventoryTransmitter();
        }        
      },
      /**
       * Load the Sensor of the curent culture
       */
      loadInventorySensor: function() {
        // Back to zero
        this.inventory.isLoaded = false
        this.inventory.data = []
        this.inventory.filtered = []
        this.selection.searchTerm = ''
        if(this.inventory.SensorPromise.length === 0)
        { 
          this.inventory.isLoaded = true
          return null;
        }
        // Send all our requests and wait on them
        Promise.all(this.inventory.SensorPromise)
        .then((res) => {
          let sensor = res[0].data.filter(s => !s.linkedToWeatherStation).map(s => {
            s.type = this.inventory.types.sensor
            s.selected = false
            return s
          })
          console.log("sensor",sensor)
          this.inventory.data = sensor
          this.inventory.data.sort(this.sortInventoryByIdASC)
          this.inventory.filtered = [...this.inventory.data]

          this.inventory.isLoaded = true
        })      
      },
      /**
       * Toggle the selected properties of a specific item
       * @param item The item that has been clicked on
       */
      toggleSelectedItem: function(item){
        item.selected = !item.selected
      },
      sortInventoryByIdASC: function(a, b){
        if(a.id === b.id){
          return 0
        }else if(a.id < b.id){
          return -1
        }else{
          return 1
        }
      },
      /**
       * Scan a code that we typed in the scan input. Two methods to entering a code :
       * 1. Manually type a code in the scan input and press enter or click on the + button.
       *    Accepted format : T or S (transmitter or sensor) follows by the material ID. We may add a dash (-) between letter and ID for more visibility.
       *    Example: T1234, T-1234, S1234, S-1234
       * 2. Automatically scan a bar code with bar code scanner (need to have the focus on the input)
       *    Accepted format: A barcode that contains T or S (transmitter or sensor) follows by the material ID.
       *    Example: T1234, S1234
       */
      scanCode: function(){
        this.cleanScanError()
  
        // We let the possibility to manually type the code with a '-' between the first letter (T | S) and the number for more clarity
        // So we remove this unwanted character for our logic
        const trimValue = this.selection.scanValue.replace('-', '')
  
        // Extract the first char to now if we search for a Transmitter (T) or a Sensor (S)
        const prefix = trimValue.substring(0,1)
        let materialTypeWanted = null
        if(prefix === 'T'){
          materialTypeWanted = this.inventory.types.transmitter
        }else if(prefix === 'S') {
          materialTypeWanted = this.inventory.types.sensor
        }else{ // Wrong letter
          this.displayScanError(this.$t('pages.export_csv.top.scan.errors.firstLetter'))
          return
        }
  
        // Fetch the ID part and cast it as an integer
        const idWanted = parseInt(trimValue.substring(1))
  
        // Search the item in the full loaded inventory and toggle it
        let scannedItem = this.inventory.data.find((item) => item.id === idWanted && item.type === materialTypeWanted)
        if(scannedItem === undefined){ // Wrong id
          this.displayScanError(this.$t('pages.export_csv.top.scan.errors.notFound'))
          return
        }
        this.toggleSelectedItem(scannedItem)
  
        // Reset value
        this.selection.scanValue = null
      },
      displayScanError: function(msg) {
        this.scanError.msg = msg
        this.scanError.display = true
      },
      cleanScanError: function() {
        this.scanError.display = false
        this.scanError.msg = null
      },
      /**
       * Called when we click on the transfer button.
       * We check if there is at least one selected item that is labeled as currently in used. If this is the case, we display
       * a warning modal. Otherwise we transfer all the material
       */
      onClickTransfer: function() {
        let usedItemFound = this.inventory.data.filter((item) => item.selected)
        console.log("usedItemFound",usedItemFound)
        if(usedItemFound == undefined){
          this.toggleWarningModal()
        }else{
          setTimeout(this.transferCSV,100)
        }
      },
      /**
       * Call the API to transfer all selected material
       */
       transferCSV: function() {
          let usedItemFound = this.inventory.data.filter((item) => item.selected)
          console.log("usedItemFound", usedItemFound)
          console.log("usedItemFound.id", usedItemFound.id)
          let ListId = usedItemFound.map(a => a.id);
          console.log("ListId",ListId)
          let listIdString = ListId.join(",");
          console.log("listIdString",listIdString)
          if(!this.loadingCSV){
            this.loadingCSV = true
            apiGetRequest(API_SENSORS_SHOW + listIdString + API_SENSORS_MEASURES_CSVEXPORT_SUFFIX)
                .then((res) => {
                  const link = generateDownloadCsvLink(res.data, res.headers['x-filename'])
                  link.click();
                })
                .finally(() => this.loadingCSV = false)
          }
       },
       toggleWarningModal: function() {
      this.warningModal.isVisible = !this.warningModal.isVisible
      }
    }
  }
  </script>
  
  <style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
  
  <style scoped>
  .page-container {
    height: calc(100vh - 136px); /* need this way to have full height space available */
  }
  .transfer-card {
    @apply bg-white shadow-md rounded-md px-4 w-full;
  }
  .user-select {
    @apply px-3 py-1 border border-gray-300 rounded-md cursor-pointer focus:outline-none focus:ring focus:ring-green-500 focus:border-green-600;
  }
  .quick-add-input {
    @apply w-60 py-2 pl-2 pr-12;
  }
  .quick-add-input__classic{
    @apply border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-green-500 focus:border-green-600;
  }
  .quick-add-input__error{
    @apply border-2 border-red-500 rounded-md focus:outline-none;
  }
  .quick-add-button {
    @apply -ml-10 py-2 px-3 text-white bg-green-500 border border-green-600 rounded-tr-md rounded-br-md hover:bg-green-600;
  }
  .search-input {
    @apply w-1/2 px-2 py-0.5 border border-gray-300 rounded-sm focus:outline-none focus:ring focus:ring-green-500 focus:border-green-600;
  }
  
  .inventory-item {
    @apply border rounded-md px-4 py-2 my-2 cursor-pointer;
  }
  .inventory-item:hover {
    box-shadow: 0 0 0 2px #059669;
  }
  .item--selected {
    @apply bg-green-500 text-white;
  }
  .item__status {
    @apply px-2 py-1 font-semibold text-white text-sm rounded-md border;
  }
  .item__status--free {
    @apply bg-green-400 border-green-600;
  }
  .item__status--used {
    @apply bg-red-400 border-red-600;
  }
  
  </style>
  
  <style>
  .multiselect__content-wrapper{
    @apply shadow-lg; /* to improve the visibility of the dropdown list above the item list */
  }
  </style>
  